
<template>
  <div class="d-flex flex-column h-100" style="overflow: auto">
    <div
      class="d-flex w-100"
      style="height: 5vh; margin-bottom: 10px"
      v-if="noImage"
    >
      <div
        class="d-flex w-100 h-100 justify-content-center"
        style="align-items: center"
      >
        <h2>No image</h2>
      </div>
    </div>

    <div
      class="d-flex w-100"
      style="max-width: 100%; margin-bottom: 10px"
      v-if="!noImage"
    >
      <div class="d-flex w-100">
        <div
          :style="
            'width:' +
            this.imgSize.width +
            'px;' +
            'height: ' +
            this.imgSize.height +
            'px;' +
            'max-width:' +
            this.imgSize.width +
            'px;' +
            'max-height: ' +
            this.imgSize.height +
            'px;'
          "
          style="position: relative; overflow: auto"
        >
          <div id="menu">
            <div>
              <button id="delete-button" @click="removePoint">
                {{ $t("shelf_zones.buttons.delete") }}
              </button>
            </div>
          </div>

          <canvas
            id="canvas"
            :width="this.imgSize.width"
            :height="this.imgSize.height"
            style="cursor: crosshair"
          ></canvas>
        </div>
        <img
          id="imageCanv"
          :src="imgSize.img"
          @error="
            () => {
              noImage = true;
            }
          "
          style="display: none"
        />
      </div>
    </div>

    <div class="d-flex flex-column">
      <div class="d-flex flex-column">
        <!-- v-card v-theme--ORANGE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated -->

        <div
          class="d-sm-flex align-center justify-space-between"
          v-if="!noImage"
        >
          <div class="v-card-title text-h5">
            <base-button
              :variant="'pure'"
              className="v-btn v-theme--ORANGE_THEME text-primary v-btn--density-default v-btn--size-default v-btn--variant-outlined"
              @click="modeAdding"
            >
              <!-- :disabled="modes.addingPoints" -->
              <span v-if="modes.addingPoints">{{
                $t("shelf_zones.buttons.disable")
              }}</span>
              <span v-else>{{ $t("shelf_zones.buttons.enable") }}</span>
            </base-button>
            <span>
              {{ $t("shelf_zones.label.add_p_mode") }}
            </span>
          </div>
        </div>

        <hr class="v-divider v-theme--ORANGE_THEME" />

        <div class="v-card-text">
          <div class="d-flex flex-row mb-2">
            <base-button
              :variant="'pure'"
              className="v-btn v-theme--ORANGE_THEME bg-secondary v-btn--density-default v-btn--size-default v-btn--variant-flat"
              @click="addNewPolygon"
            >
              <span
                style="font-size: 30px; line-height: 30px; margin-right: 5px"
                >+</span
              >
              <span>{{ $t("shelf_zones.buttons.add_figure") }}</span>
            </base-button>
          </div>
          <div class="d-flex w-100 flex-column">
            <div class="d-flex flex-column w-100" style="">
              <div
                v-for="(itm, ind1) in polygons"
                :key="ind1"
                style="
                  min-height: 45px;
                  padding: 10px 5px;
                  margin: 0px;
                  margin-bottom: 0.5rem;
                "
                :style="[
                  selectedFigure && selectedFigure.id == itm.id
                    ? 'background-color: rgb(0 152 255 / 20%);'
                    : '',
                ]"
                class="d-flex w-100 flex-row align-items-center v-btn v-theme--ORANGE_THEME border-choosen"
                @click="selectPolygon(itm)"
              >
                <div
                  class="d-flex flex-column w-100 align-items-center h-100"
                  style="flex: 0 0 10%; padding: 0px 5px"
                >
                  <base-input
                    labelName="№"
                    v-model:value="itm.sorting_order"
                    placeholder=""
                    ref="ordInput"
                    type="number"
                    style="margin-bottom: 15px"
                    :step="1"
                  ></base-input>

                  <BaseSwitcher
                    class=""
                    :readonly="false"
                    :disabled="false"
                    :value="itm.active"
                    @change-value="
                      (newv) => {
                        itm.active = newv;
                      }
                    "
                  ></BaseSwitcher>
                </div>

                <div class="d-flex flex-column" style="flex: 0 0 75%">
                  <div class="d-flex flex-row w-100 align-items-center">
                    <div
                      class="d-flex flex-column"
                      style="flex: 0 0 80%; padding-right: 5px"
                    >
                      <base-multiselect
                        class="base-multiselect__item"
                        :placeholder="$t('shelf_zones.label.prod')"
                        :items="products"
                        :value="itm.product"
                        :multiple="false"
                        :labelProp="'name'"
                        :closeOnSelect="true"
                        :search="true"
                        @select="(payload) => selectItem(payload, itm)"
                      ></base-multiselect>
                    </div>

                    <div class="d-flex flex-column" style="flex: 0 0 20%">
                      <base-input
                        v-if="!shelfWeight"
                        labelName="Threshold"
                        v-model:value="itm.threshold"
                        placeholder="Threshold"
                        ref="threshInput"
                        type="number"
                        :step="0.1"
                      ></base-input>

                      <base-input
                        v-else
                        labelName="Port"
                        v-model:value="itm.weight_port"
                        placeholder=""
                        ref="weight_portInput"
                        type="number"
                        :step="1"
                      ></base-input>
                    </div>
                  </div>
                  <div
                    class="d-flex flex-row w-100 align-items-center mt-2"
                    style="justify-content: flex-end"
                  >
                    <!-- <base-multiselect
                            class="base-multiselect__item"
                            :placeholder="$t('shelf_zones.label.av_prods')"
                            :items="products"
                            :value="itm.available_items"
                            :multiple="true"
                            :chips="true"
                            :canClear="true"
                            :labelProp="'name'"
                            :closeOnSelect="false"
                            :search="true"
                            @select="(payload) => {itm.available_items = payload;}"
                          ></base-multiselect> -->

                    <div
                      class="d-flex flex-row"
                      style="flex: 0 0 20%; padding-right: 5px"
                    >
                      <base-input
                        :labelName="$t('shelf_zones.label.minq')"
                        v-model:value="itm.min_quantity"
                        placeholder=""
                        ref="minInput"
                        type="number"
                        :step="1"
                      ></base-input>
                    </div>
                    <div class="d-flex flex-row" style="flex: 0 0 20%">
                      <base-input
                        :labelName="$t('shelf_zones.label.maxq')"
                        v-model:value="itm.max_quantity"
                        placeholder=""
                        ref="maxInput"
                        type="number"
                        :step="1"
                      ></base-input>
                    </div>
                  </div>
                </div>

                <div
                  class="d-flex flex-column justify-content-around"
                  style="flex: 0 0 15%"
                >
                  <base-button
                    v-if="!selectedFigure || selectedFigure.id != itm.id"
                    :variant="'pure'"
                    className="v-btn justify-content-around v-theme--ORANGE_THEME bg-success v-btn--density-default v-btn--size-default v-btn--variant-flat mb-1"
                    @click="selectPolygon(itm)"
                  >
                    {{ $t("shelf_zones.buttons.sel_figure") }}
                  </base-button>

                  <base-button
                    :variant="'pure'"
                    className="v-btn justify-content-around v-theme--ORANGE_THEME text-primary v-btn--density-default v-btn--size-default v-btn--variant-outlined"
                    @click="removePolygon(ind1)"
                  >
                    {{ $t("shelf_zones.buttons.del_figure") }}
                  </base-button>
                </div>
              </div>
            </div>

            <div
              v-if="(noImage && polygons.length > 0) || !noImage"
              class="d-flex flex-column w-100 mt-2 justify-content-center"
            >
              <hr class="v-divider v-theme--ORANGE_THEME" />
              <div class="d-flex justify-content-center mt-2">
                <base-button
                  :disabled="block_savebtn"
                  :variant="'pure'"
                  className="v-btn v-theme--ORANGE_THEME bg-success v-btn--density-default v-btn--size-default v-btn--variant-flat"
                  @click="savePlgns()"
                >
                  <h2>{{ $t("shelf_zones.buttons.save") }}</h2>
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <spinner v-if="spinnerShow > 0"></spinner>
  </div>
</template>


<script>
//import imaag from "@/assets/img/map_sample.jpg";
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseMultiselect from "@/components/BaseMultiselect_new.vue";
import EntityApi from "@/api/entityApi";
import Spinner from "../Spinner.vue";
import BaseSwitcher from "@/components/BaseSwitcher";

export default {
  name: "drawZones-page",
  props: {
    id: {
      type: String,
      required: false,
    },
    data: {
      type: Object,
    },
  },
  components: {
    BaseButton,
    BaseInput,
    BaseMultiselect,
    Spinner,
    BaseSwitcher,
  },
  emits: ["closeModalf"],
  data() {
    return {
      imgSize: {
        width: 0,
        height: 0,
        img: null,
      },

      shelfWeight: false,

      polygonHovered: null,
      selectedFigure: null,
      polygons: [],

      products: [],

      canvasEl: null,
      ctx: null,
      activePoint: null,
      cursor: "",
      dragging: false,
      mouse: { x: 0, y: 0, button: 0, lx: 0, ly: 0, update: true },

      modes: {
        addingPoints: false,
      },
      timerDisableAddPoint: null,
      minPointsFigr: 3,

      menuShow: false,
      manipActPoint: null,

      defThreshold: "0.6",

      noImage: false,
      spinnerShow: 0,

      changes: false,

      block_savebtn: false,
    };
  },
  methods: {
    async canvasInit() {
      this.canvasEl = document.getElementById("canvas");
      if (this.canvasEl) {
        this.ctx = this.canvasEl.getContext("2d");
      }

      if (this.ctx) {
        await this.getPhoto(this.id);
        await this.getZones();
        //this.ctx.clearRect(0,0,this.imgSize.width,this.imgSize.height);

        if (!this.noImage) {
          setTimeout(() => {
            this.mouse.update = true;
            this.update();
          }, 500);

          this.addEvents();
        }
      }
    },
    polygonsdraw() {
      this.ctx.lineWidth = 2;
      this.ctx.drawImage(document.getElementById("imageCanv"), 0, 0);
      this.polygonHovered = null;

      for (let i = 0; i < this.polygons.length; i++) {
        this.ctx.beginPath();
        for (const p of this.polygons[i].points) {
          this.ctx.lineTo(p.x, p.y);
        }
        this.ctx.closePath();
        for (const p of this.polygons[i].points) {
          this.ctx.moveTo(p.x + 4, p.y);
          this.ctx.arc(p.x, p.y, 4, 0, Math.PI * 2);
        }
        this.ctx.strokeStyle = this.polygons[i].rgb; //колір ліній
        if (
          this.selectedFigure &&
          this.polygons[i].id == this.selectedFigure.id
        ) {
          //колір для вибраної фігури
          this.ctx.strokeStyle = "rgb(255, 0, 0)";
          this.ctx.fillStyle = this.polygons[i].rgba;
          this.ctx.fill();
        }

        if (
          this.ctx.isPointInPath(this.mouse.x, this.mouse.y) ||
          (this.activePoint &&
            this.ctx.isPointInPath(this.activePoint.x, this.activePoint.y))
        ) {
          //this.ctx.globalAlpha = 0.2
          this.ctx.fillStyle = this.polygons[i].rgba;
          this.ctx.fill();

          this.polygonHovered = this.polygons[i];
        }

        this.ctx.stroke();
      }
    },
    findDragPoint(pos) {
      let x = pos.x;
      let y = pos.y;
      function hitTest(p, x, y) {
        var dx = p.x - x,
          dy = p.y - y;
        return Math.sqrt(dx * dx + dy * dy) <= 10;
      }

      for (let i = 0; i < this.polygons.length; i++) {
        for (let j = 0; j < this.polygons[i].points.length; j++) {
          if (hitTest(this.polygons[i].points[j], x, y)) {
            return this.polygons[i].points[j];
          }
        }
      }
    },
    addEvents() {
      document
        .getElementById("canvas")
        .addEventListener("mousedown", this.mouseEvents);
      document
        .getElementById("canvas")
        .addEventListener("mouseup", this.mouseEvents);
      document
        .getElementById("canvas")
        .addEventListener("mousemove", this.mouseEvents);
    },
    removeEvents() {
      if (this.noImage) {
        return;
      }
      document
        .getElementById("canvas")
        .removeEventListener("mousedown", this.mouseEvents);
      document
        .getElementById("canvas")
        .removeEventListener("mouseup", this.mouseEvents);
      document
        .getElementById("canvas")
        .removeEventListener("mousemove", this.mouseEvents);
    },
    mouseEvents(e) {
      if (this.noImage) {
        return;
      }
      const bounds = document.getElementById("canvas").getBoundingClientRect();
      this.mouse.x = e.pageX - bounds.left - scrollX;
      this.mouse.y = e.pageY - bounds.top - scrollY;
      this.mouse.button =
        e.type === "mousedown"
          ? true
          : e.type === "mouseup"
          ? false
          : this.mouse.button;
      this.mouse.update = true;

      if (e.type === "mousedown") {
        this.menuShow = false;
      }

      if (e.type === "mousedown") {
        if (this.polygonHovered) {
          this.selectedFigure = this.polygonHovered;
        }
      }
    },
    drawCircle(pos, color = "red", size = 8) {
      this.ctx.strokeStyle = color;
      this.ctx.beginPath();
      this.ctx.arc(pos.x, pos.y, size, 0, Math.PI * 2);
      this.ctx.stroke();
    },
    update() {
      if (this.noImage) {
        return;
      }
      if (this.mouse.update) {
        this.cursor = "crosshair";
        this.ctx.lineWidth = 2;

        if (this.ctx) {
          this.ctx.clearRect(
            0,
            0,
            document.getElementById("canvas").width,
            document.getElementById("canvas").height
          );
        }

        if (!this.dragging) {
          this.activePoint = this.findDragPoint(this.mouse);
        }
        if (this.activePoint === undefined && this.mouse.button) {
          this.addPoint(this.mouse);
          this.mouse.button = false;

          this.menuShow = false;
        } else if (this.activePoint) {
          if (this.mouse.button) {
            if (this.dragging) {
              this.menuShow = false;

              this.activePoint.x += this.mouse.x - this.mouse.lx;
              this.activePoint.y += this.mouse.y - this.mouse.ly;
            } else {
              this.dragging = true;

              this.menuShow = true;
              let menuNode = document.getElementById("menu");
              //menuNode.style.display = 'initial';
              //var containerRect = stage.container().getBoundingClientRect();
              //menuNode.style.top = containerRect.top + this.mouse.y + 4 + 'px';
              //menuNode.style.left = containerRect.left + this.mouse.x + 4 + 'px';

              menuNode.style.top = this.mouse.y + 4 + "px";
              menuNode.style.left = this.mouse.x + 4 + "px";
            }
          } else {
            this.dragging = false;
          }
        }
        this.polygonsdraw();

        if (this.activePoint) {
          this.drawCircle(this.activePoint);
          this.cursor = "pointer";
        }

        this.mouse.lx = this.mouse.x;
        this.mouse.ly = this.mouse.y;
        document.getElementById("canvas").style.cursor = this.cursor;
        this.mouse.update = false;
      }
      requestAnimationFrame(this.update);
    },
    randomColor() {
      //return('#'+Math.floor(Math.random()*16777215).toString(16));
      let trans = "0.6";
      let color = "rgba(";
      for (let i = 0; i < 3; i++) {
        color += Math.floor(Math.random() * 255) + ",";
      }
      let rgb = color + 1 + ")"; // add the transparency
      let rgba = color + trans + ")";
      return { rgb: rgb, rgba: rgba };
    },

    async getPhoto(id_shelf) {
      this.spinnerShow++;
      let data = await EntityApi.getShelfPhoto(id_shelf);

      if (data.file_url) {
        this.imgSize.img = data.file_url;
        this.getImageSize();
        this.noImage = false;
        this.spinnerShow--;
      } else {
        this.noImage = true;
        this.spinnerShow--;
      }
    },
    async getZones() {
      this.polygons = [];
      this.spinnerShow++;
      let data = await EntityApi.getEntityItems("shelf_section", {
        shelf_id__eq: this.id,
      });

      let order = 0;

      data.map((zone) => {
        order = order + 1;
        let coord = [];
        if (zone.data.all_points_x && zone.data.all_points_y) {
          for (let i = 0; i < zone.data.all_points_x.length; i++) {
            coord.push({
              x: zone.data.all_points_x[i],
              y: zone.data.all_points_y[i],
            });
          }
        }

        let zoneItm = {};

        if (zone.active == undefined) {
          zoneItm.active = true;
        } else {
          zoneItm.active = zone.active;
        }

        if (zone.sorting_order) {
          zoneItm.sorting_order = zone.sorting_order;
        } else {
          zoneItm.sorting_order = order;
        }

        if (zone.item) {
          zoneItm.product = zone.item;
        }
        if (zone.min_quantity) {
          zoneItm.min_quantity = zone.min_quantity;
        } else if (zone.item && zone.item.min_balance_quantity) {
          zoneItm.min_quantity = zone.item.min_balance_quantity;
        }
        if (!zoneItm.min_quantity) {
          zoneItm.min_quantity = 1;
        }

        zoneItm.max_quantity = zone.max_quantity ? zone.max_quantity : null;
        if (zone.weight_slave_id != undefined) {
          zoneItm.weight_slave_id = zone.weight_slave_id;
        }
        if (zone.weight_port != undefined) {
          zoneItm.weight_port = zone.weight_port;
        }
        if (zone.weight_controller) {
          zoneItm.weight_controller = zone.weight_controller;
        }

        if (zone.min_threshold) {
          zoneItm.threshold = zone.min_threshold;
        }

        if (zone.available_items && zone.available_items.length > 0) {
          zoneItm.available_items = zone.available_items;
        } else {
          zoneItm.available_items = [];
        }

        if (coord.length > 0) {
          zoneItm.points = coord;
        }

        zoneItm.type = zone.data.name;
        this.addNewPolygon(zoneItm);
      });

      this.polygons.sort((a, b) => a.sorting_order - b.sorting_order);
      this.spinnerShow--;

      if (!this.noImage && this.polygons.length < 1) {
        this.addNewPolygon();
      }
    },
    findPntPlgn(x, y) {
      for (let i = 0; i < this.polygons.length; i++) {
        for (let j = 0; j < this.polygons[i].points.length; j++) {
          if (
            x == this.polygons[i].points[j].x &&
            y == this.polygons[i].points[j].y
          ) {
            return { indPlgn: i, indP: j };
          }
        }
      }
      return null;
    },
    addPoint(p) {
      let point = (x, y) => ({ x, y });
      if (this.modes.addingPoints) {
        if (this.polygons.length < 1) {
          this.addNewPolygon();
        }

        if (this.selectedPolygon != null) {
          this.polygons[this.selectedPolygon].points.push(point(p.x, p.y));
          this.smth_changed();
        } else if (this.polygons.length == 1) {
          this.polygons[0].points.push(point(p.x, p.y));
          this.smth_changed();
        }

        this.disableAddPTimer();
      }
    },
    modeAdding() {
      this.modes.addingPoints = !this.modes.addingPoints;
    },
    removePoint() {
      if (this.manipActPoint) {
        let indexes = this.findPntPlgn(
          this.manipActPoint.x,
          this.manipActPoint.y
        );
        if (indexes) {
          this.polygons[indexes.indPlgn].points.splice(indexes.indP, 1);
          this.checkPointsLast(indexes.indPlgn);
          this.smth_changed();
        }

        this.mouse.update = true;
        this.update();
      }
      this.menuShow = false;
    },
    checkPointsLast(ind = null) {
      let index = null;
      if (ind != undefined) {
        index = ind;
      } else {
        if (this.polygons.length > 0) {
          index = this.polygons.length - 1;
        }
      }

      if (
        index != undefined &&
        this.polygons[index] &&
        !this.noImage &&
        this.polygons[index].points.length < this.minPointsFigr
      ) {
        this.removePolygon(index);
      }
    },
    addNewPolygon(data = null) {
      let color = this.randomColor();
      this.checkPointsLast();

      let tresh = this.defThreshold,
        type = "polygon",
        points = [],
        product = null,
        available_items = [],
        sorting_order = this.polygons.length ? this.polygons.length + 1 : 1,
        weight_slave_id = null,
        weight_port = null,
        weight_controller = null,
        min_quantity = null,
        max_quantity = null,
        aact = true;

      if (data) {
        if (data.points) {
          points = data.points;
        }
        if (data.active != undefined) {
          aact = data.active;
        }
        if (data.threshold) {
          tresh = data.threshold;
        }
        if (data.type) {
          type = data.type;
        }

        if (data.available_items && data.available_items.length > 0) {
          available_items = data.available_items;
        }

        if (data.sorting_order) {
          sorting_order = data.sorting_order;
        }

        if (data.product) {
          product = data.product;
        }

        if (data.min_quantity) {
          min_quantity = data.min_quantity;
        } else if (data.product && data.product.min_balance_quantity) {
          min_quantity = data.product.min_balance_quantity;
        }
        if (!min_quantity) {
          min_quantity = 1;
        }

        if (data.max_quantity) {
          max_quantity = data.max_quantity;
        }
        if (data.weight_slave_id != undefined) {
          weight_slave_id = data.weight_slave_id;
        }
        if (data.weight_port != undefined) {
          weight_port = data.weight_port;
        }
        if (data.weight_controller) {
          weight_controller = data.weight_controller;
        }
      }

      let id = Math.floor(Math.random() * 4000);
      let obj = {
        id: id,
        name: "figure without product",
        threshold: tresh,
        product: product,
        available_items: available_items,
        sorting_order: sorting_order,
        active: aact,

        type: type,
        points: points,
        rgba: color.rgba,
        rgb: color.rgb,
      };
      if (weight_slave_id != undefined) {
        obj.weight_slave_id = weight_slave_id;
      }
      if (weight_port != undefined) {
        obj.weight_port = weight_port;
      }
      if (weight_controller) {
        obj.weight_controller = weight_controller;
      }
      if (min_quantity) {
        obj.min_quantity = min_quantity;
      }
      if (max_quantity) {
        obj.max_quantity = max_quantity;
      }

      this.polygons.push(obj);
      this.selectedFigure = this.polygons[this.polygons.length - 1];

      this.smth_changed();
      this.modes.addingPoints = true;
    },
    selectPolygon(value) {
      //this.polygonHovered
      this.mouse.update = true;

      if (value) {
        this.selectedFigure = value;
      }
      this.update();
    },
    removePolygon(index) {
      if (
        this.selectedFigure &&
        this.selectedFigure.id == this.polygons[index].id
      ) {
        this.selectedFigure = null;
      }

      this.polygons.splice(index, 1);
      this.smth_changed();

      this.mouse.update = true;
      this.update();
    },
    getImageSize() {
      if (this.imgSize.img) {
        var i = new Image();
        i.onload = () => {
          this.imgSize.height = i.height;
          this.imgSize.width = i.width;

          //this.ctx.drawImage(this.imgSize.img, 0, 0);
        };
        i.src = this.imgSize.img;
      }
    },
    inside(p, vs) {
      var inside = false;
      for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i].x,
          yi = vs[i].y;
        var xj = vs[j].x,
          yj = vs[j].y;
        var intersect =
          yi > p.y != yj > p.y &&
          p.x < ((xj - xi) * (p.y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }
      return inside;
    },
    disableAddPTimer() {
      clearTimeout(this.timerDisableAddPoint);
      this.timerDisableAddPoint = setTimeout(() => {
        if (this.polygons.length > 0) {
          this.modes.addingPoints = false;
          this.checkPointsLast();
        }
      }, 7000);
    },
    findPolygon(item) {
      if (item) {
        let ind = -1;
        if (item) {
          ind = this.polygons.findIndex((el) => {
            return el.id == item.id;
          });
        }
        if (ind > -1) {
          return ind;
        }
      }
      return null;
    },
    savePlgns() {
      let arr = [];
      let remove_arr = [];

      this.block_savebtn = true;
      this.spinnerShow++;

      this.polygons.map((itm) => {
        if (
          this.data &&
          this.data.company &&
          this.data.company.id &&
          this.data.id &&
          itm.product &&
          itm.product.id_ai &&
          (itm.points.length > 0 || this.noImage)
        ) {
          let all_points_x = [],
            all_points_y = [],
            obj = {};
          itm.points.map((coord) => {
            all_points_x.push(coord.x);
            all_points_y.push(coord.y);
          });

          obj = {
            active: itm.active,
            product_id: itm.product.id,
            available_items: [],
            threshold: itm.threshold,
            all_points_x: all_points_x,
            all_points_y: all_points_y,
            sorting_order: itm.sorting_order,
          };
          if (itm.weight_slave_id != undefined) {
            obj.weight_slave_id = itm.weight_slave_id;
          }
          if (itm.weight_port != undefined) {
            obj.weight_port = itm.weight_port;
          }
          if (itm.weight_controller) {
            obj.weight_controller_id = itm.weight_controller.id;
          }
          if (itm.min_quantity) {
            obj.min_quantity = itm.min_quantity;
          }
          if (itm.max_quantity) {
            obj.max_quantity = itm.max_quantity;
          }

          if (itm.available_items.length > 0) {
            for (let i = 0; i < itm.available_items.length; i++) {
              obj.available_items.push(itm.available_items[i].id);
            }
          }

          arr.push(obj);
        } else {
          remove_arr.push(itm);
        }
      });

      if (remove_arr.length > 0) {
        for (let i = 0; i < remove_arr.length; i++) {
          let temp = this.findPolygon(remove_arr[i]);
          if (temp) {
            this.removePolygon(temp);
          }
        }
      }

      EntityApi.sendShelvesZones({
        shelf_id: this.data.id,
        sections: arr,
      })
        .then(() => {
          this.$notify(
            {
              group: "alert",
              type: "info",
              title: this.$t("shelf_zones.label.saved"),
              text: this.$t("shelf_zones.label.saved_text"),
            },
            3000
          );

          this.spinnerShow--;

          this.$emit("closeModalf", null);
        })
        .catch(() => {
          this.$notify(
            {
              group: "alert",
              type: "error",
              title: "Error",
              text: this.$t("shelf_zones.label.saved_not_text"),
            },
            3000
          );

          this.spinnerShow--;
          this.block_savebtn = false;
        });
    },
    selectItem(resp, value) {
      this.selectPolygon(value);
      value.product = resp;
    },
    smth_changed() {
      this.changes = true;
    },
    async getProducts() {
      this.spinnerShow++;
      this.products = await EntityApi.getEntityItems("Item");
      this.spinnerShow--;
    },
  },
  mounted() {
    this.canvasInit();
    this.getProducts();

    if (this.data.weight_slave_id) {
      this.shelfWeight = true;
    }
  },
  beforeUnmount() {
    this.removeEvents();
  },
  sockets: {},
  computed: {
    selectedPolygon() {
      if (this.selectedFigure) {
        let ind = this.polygons.findIndex((el) => {
          return el.id == this.selectedFigure.id;
        });
        if (ind > -1) {
          return ind;
        }
      }
      return null;
    },
  },
  watch: {
    "modes.addingPoints": {
      handler(val) {
        if (val) {
          this.disableAddPTimer();
        } else {
          this.checkPointsLast();
        }
      },
      deep: true,
    },
    menuShow(newVal) {
      let menuNode = document.getElementById("menu");
      if (newVal) {
        menuNode.style.display = "initial";
        this.manipActPoint = JSON.parse(JSON.stringify(this.activePoint));
      } else {
        menuNode.style.display = "none";
        this.manipActPoint = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#menu {
  display: none;
  position: absolute;
  width: 60px;
  background-color: white;
  box-shadow: 0 0 5px grey;
  border-radius: 3px;
  z-index: 1;
}

#menu button {
  width: 100%;
  background-color: white;
  border: none;
  margin: 0;
  padding: 10px;
}

#menu button:hover {
  background-color: lightgray;
}
.v-btn {
  margin: 0px 5px;
}
.base-multiselect__value {
  border-color: #e2e8f0 !important;
  background-color: red;
}

.value__list {
  flex-wrap: wrap;
}

.border-choosen {
  border: var(--bms-border-width, 1px) solid var(--bms-border-color, #000);
  border-radius: var(--bms-border-radius, 4px);
  &:hover {
    border-color: var(--bms-border-color-hover, #acacff);
  }
}
</style>