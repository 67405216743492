export let getUserLocale = () => {
  let availableLocale = process.env.VUE_APP_I18N_AVAILABLE_LOCALES.split(",");
  let fallbacks = { uk_UA: "ua", en_US: "en", en_GB: "en" };

  let saveadLocale = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "en";
  if (saveadLocale?.length) {
    const normalizedLang = fallbacks[saveadLocale] || saveadLocale;

    saveadLocale = normalizedLang.toLowerCase();
  } else {
    saveadLocale = process.env.VUE_APP_I18N_LOCALE;
  }

  if (saveadLocale?.length) {
    return saveadLocale;
  } else if (window.navigator.userLanguage || window.navigator.language) {
    let userLanguage =
      window.navigator.userLanguage || window.navigator.language;
    if (availableLocale.includes(userLanguage.slice(0, 2))) {
      return userLanguage.slice(0, 2);
    } else return process.env.VUE_APP_I18N_LOCALE;
  } else {
    return process.env.VUE_APP_I18N_LOCALE;
  }
};
import ua from "./locales/ua.json";
import en from "./locales/en.json";

export function loadLocaleMessages() {
  let locales = {
    ua: ua,
    en: en,
  };
  return locales;
}
